<template>
  <div>
    <div id="top-bar-one" class="text-center pt-5 pb-5">
      <v-container> <Logo width="160" /> </v-container>
    </div>
    <div v-if="isLoaded && salesData">
      <div
        id="top-bar-two"
        class="text-center vollkorn"
        v-touch="{
          left: () => swipe('left'),
          right: () => swipe('right'),
        }"
      >
        <v-container>
          Daily report {{ getReadableDate }}
          <template v-if="isAdmin">
            <v-menu
              v-model="calendar"
              :close-on-content-click="false"
              transition="slide-y-transition"
              nudge-bottom="35"
              :nudge-left="breakpoint.mdAndUp ? '160' : '0'"
              z-index="111111111111111111"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  color="avonovaSignalGreen"
                  class="calendar-icon ml-2"
                >
                  {{ calendar ? "mdi-close" : "mdi-arrow-down" }}
                </v-icon>
              </template>
              <div class="d-flex flex-column">
                <div>
                  <v-date-picker
                    :showWeek="true"
                    width="350"
                    :fullWidth="breakpoint.smAndDown"
                    ref="picker"
                    v-model="d"
                    min="2020-07-01"
                    :max="new Date().toISOString().substr(0, 10)"
                    header-color="avonovaDarkGreen"
                    color="avonovaDarkGreen"
                    locale="en-GB"
                    @change="newDateSelected = true"
                  ></v-date-picker>
                  <!-- old locale nb-no -->
                </div>
                <div
                  class="d-flex flex-row justify-center pb-6 pt-6"
                  style="background: white"
                >
                  <v-btn
                    small
                    class="white--text mr-2"
                    color="darkgray"
                    depressed
                    @click="calendar = false"
                  >
                    CANCEL
                  </v-btn>
                  <v-btn
                    class="white--text"
                    small
                    color="primary"
                    depressed
                    v-show="newDateSelected"
                    @click="pickNewDate(d)"
                  >
                    OK
                  </v-btn>
                </div>
              </div>
            </v-menu>
          </template>
        </v-container>
      </div>
      <div
        id="top-bar-three"
        class="text-center mb-5 pa-2 d-flex justify-center"
        style="background: #93bcb4"
      >
        <div class="col-12 col-md-2 pa-0">
          <v-menu v-model="showLeadMenu" transition="scale-transition" offset-y>
            <template v-slot:activator="{ props }">
              <div
                style="
                  background-color: #1e453e;
                  background-image: linear-gradient(
                    314deg,
                    #1e453e 0%,
                    #182c25 84%
                  );
                  color: white;
                  cursor: pointer;
                  text-transform: uppercase;
                  border-radius: 10px;
                "
                class="pa-1"
                v-bind="props"
                @click="showLeadMenu = !showLeadMenu"
              >
                Send in a lead
              </div>
            </template>
            <ul id="list-menu">
              <li>
                <a
                  href="https://info.avonova.com/leads"
                  target="_blank"
                  style="display: block; width: 100%"
                  class="d-flex align-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 512 512"
                  >
                    <mask id="a">
                      <circle cx="256" cy="256" r="256" fill="#fff" />
                    </mask>
                    <g mask="url(#a)">
                      <path
                        fill="#d80027"
                        d="M0 0h100.2l66.1 53.5L233.7 0H512v189.3L466.3 257l45.7 65.8V512H233.7l-68-50.7-65.5 50.7H0V322.8l51.4-68.5-51.4-65z"
                      />
                      <path
                        fill="#eee"
                        d="M100.2 0v189.3H0v33.4l24.6 33L0 289.5v33.4h100.2V512h33.4l30.6-26.3 36.1 26.3h33.4V322.8H512v-33.4l-24.6-33.7 24.6-33v-33.4H233.7V0h-33.4l-33.8 25.3L133.6 0z"
                      />
                      <path
                        fill="#0052b4"
                        d="M133.6 0v222.7H0v66.7h133.6V512h66.7V289.4H512v-66.7H200.3V0z"
                      />
                    </g></svg
                  ><span>Norway</span></a
                >
              </li>
              <li>
                <a
                  href="https://info.avonova.se/leads"
                  target="_blank"
                  style="display: block; width: 100%"
                  class="d-flex align-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 512 512"
                  >
                    <mask id="a">
                      <circle cx="256" cy="256" r="256" fill="#fff" />
                    </mask>
                    <g mask="url(#a)">
                      <path
                        fill="#0052b4"
                        d="M0 0h133.6l35.3 16.7L200.3 0H512v222.6l-22.6 31.7 22.6 35.1V512H200.3l-32-19.8-34.7 19.8H0V289.4l22.1-33.3L0 222.6z"
                      />
                      <path
                        fill="#ffda44"
                        d="M133.6 0v222.6H0v66.8h133.6V512h66.7V289.4H512v-66.8H200.3V0z"
                      />
                    </g>
                  </svg>
                  <span>Sweden</span></a
                >
              </li>
            </ul>
          </v-menu>
        </div>
      </div>
      <v-main>
        <v-container>
          <div id="goal" class="d-none d-sm-block text-center mt-10 mb-15">
            <span class="goal-1 d-block mb-7"
              >{{ thisMonth.toUpperCase() }} GOAL:</span
            >
            <span class="goal-2 d-block"
              >{{ this.goalThisMonth }} LICENCES</span
            >
          </div>
          <!-- Overordnede team mål START -->
          <div
            id="team-goals"
            class="d-flex flex-row mb-12 text-center flex-wrap"
          >
            <div class="d-flex flex-wrap flex-row col-12 justify-center">
              <div
                class="col-6 col-md-3 d-flex flex-row justify-center pa-4 pa-md-3 mr-0 mr-md-4 mb-4"
                :class="[
                  { 'team-goals-column': breakpoint.mdAndUp },
                  norwayTeamTotalClass,
                ]"
              >
                <div class="d-flex align-center">
                  <div class="d-flex flex-column">
                    <div class="d-flex justify-center mb-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 512 512"
                      >
                        <mask id="a">
                          <circle cx="256" cy="256" r="256" fill="#fff" />
                        </mask>
                        <g mask="url(#a)">
                          <path
                            fill="#d80027"
                            d="M0 0h100.2l66.1 53.5L233.7 0H512v189.3L466.3 257l45.7 65.8V512H233.7l-68-50.7-65.5 50.7H0V322.8l51.4-68.5-51.4-65z"
                          />
                          <path
                            fill="#eee"
                            d="M100.2 0v189.3H0v33.4l24.6 33L0 289.5v33.4h100.2V512h33.4l30.6-26.3 36.1 26.3h33.4V322.8H512v-33.4l-24.6-33.7 24.6-33v-33.4H233.7V0h-33.4l-33.8 25.3L133.6 0z"
                          />
                          <path
                            fill="#0052b4"
                            d="M133.6 0v222.7H0v66.7h133.6V512h66.7V289.4H512v-66.7H200.3V0z"
                          />
                        </g>
                      </svg>
                    </div>
                    <div>
                      <div class="text-uppercase mb-2">Sales</div>
                      <div class="text-medium mb-2">
                        Today:
                        <ICountUp
                          class="counter"
                          :delay="0"
                          :endVal="norwayTeamToday"
                          :options="{
                            useEasing: true,
                            duration: 10,
                          }"
                        />
                      </div>
                      <div class="text-medium font-weight-black">
                        Total:
                        <ICountUp
                          class="counter"
                          :delay="200"
                          :endVal="norwayTeamTotal"
                          :options="{
                            useEasing: true,
                            duration: 10,
                          }"
                          @ready="onNorwayReady"
                        />
                        of {{ norwayTeamGoalMonth }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-6 col-md-3 d-flex flex-row justify-center pa-4 pa-md-3 mb-4 mr-0 mr-md-4"
                :class="[
                  { 'team-goals-column': breakpoint.mdAndUp },
                  swedenTeamTotalClass,
                ]"
              >
                <div class="d-flex align-center">
                  <div class="d-flex flex-column">
                    <div class="d-flex justify-center mb-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 512 512"
                      >
                        <mask id="a">
                          <circle cx="256" cy="256" r="256" fill="#fff" />
                        </mask>
                        <g mask="url(#a)">
                          <path
                            fill="#0052b4"
                            d="M0 0h133.6l35.3 16.7L200.3 0H512v222.6l-22.6 31.7 22.6 35.1V512H200.3l-32-19.8-34.7 19.8H0V289.4l22.1-33.3L0 222.6z"
                          />
                          <path
                            fill="#ffda44"
                            d="M133.6 0v222.6H0v66.8h133.6V512h66.7V289.4H512v-66.8H200.3V0z"
                          />
                        </g>
                      </svg>
                    </div>
                    <div>
                      <div class="text-uppercase mb-2">Sales</div>
                      <div class="text-medium mb-2">
                        Today:
                        <ICountUp
                          class="counter"
                          :delay="0"
                          :endVal="swedenTeamToday"
                          :options="{
                            useEasing: true,
                            duration: 10,
                          }"
                        />
                      </div>
                      <div class="text-medium font-weight-black">
                        Total:
                        <ICountUp
                          class="counter"
                          :delay="0"
                          :endVal="swedenTeamTotal"
                          :options="{
                            useEasing: true,
                            duration: 10,
                          }"
                          @ready="onSwedenReady"
                        />
                        of {{ swedenTeamGoalMonth }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- finland -->
              <div
                class="col-6 col-md-3 d-flex flex-row justify-center pa-4 pa-md-3 mb-4 mr-0 mr-md-4"
                :class="[
                  { 'team-goals-column': breakpoint.mdAndUp },
                  finlandTeamTotalClass,
                ]"
              >
                <div class="d-flex align-center">
                  <div class="d-flex flex-column">
                    <div class="d-flex justify-center mb-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        fill="none"
                        viewBox="0 0 512 512"
                      >
                        <g clip-path="url(#a)">
                          <mask
                            id="b"
                            width="512"
                            height="512"
                            x="0"
                            y="0"
                            maskUnits="userSpaceOnUse"
                            style="mask-type: alpha"
                          >
                            <path
                              fill="#F0F0F0"
                              d="M256 512c141.385 0 256-114.615 256-256S397.385 0 256 0 0 114.615 0 256s114.615 256 256 256Z"
                            />
                          </mask>
                          <g mask="url(#b)">
                            <circle
                              cx="260.5"
                              cy="265.5"
                              r="274.5"
                              fill="#F0F0F0"
                            />
                            <path
                              fill="#0052B4"
                              stroke="#0052B4"
                              stroke-width="2"
                              d="m510.825 222.48-.114-.871H201.349l-.001-215.524V4.839l-1.216.27a255.264 255.264 0 0 0-67.046 25.14l-.521.285v191.073H1.289l-.114.871A259.19 259.19 0 0 0-1 256c0 11.361.745 22.548 2.175 33.52l.114.871h131.276v191.075l.521.284a255.247 255.247 0 0 0 67.046 25.14l1.216.27V290.392h309.363l.114-.871A259.638 259.638 0 0 0 513 256a259.61 259.61 0 0 0-2.175-33.52Z"
                            />
                          </g>
                        </g>
                        <defs>
                          <clipPath id="a">
                            <path fill="#fff" d="M0 0h512v512H0z" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div>
                      <div class="text-uppercase mb-2">Sales</div>
                      <div class="text-medium mb-2">
                        Today:
                        <ICountUp
                          class="counter"
                          :delay="0"
                          :endVal="finlandTeamToday"
                          :options="{
                            useEasing: true,
                            duration: 10,
                          }"
                        />
                      </div>
                      <div class="text-medium font-weight-black">
                        Total:
                        <ICountUp
                          class="counter"
                          :delay="0"
                          :endVal="finlandTeamTotal"
                          :options="{
                            useEasing: true,
                            duration: 10,
                          }"
                          @ready="onFinlandReady"
                        />
                        of {{ finlandTeamGoalMonth }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- finland end -->
              <div
                class="col-6 col-md-3 d-flex flex-row justify-center pa-4 pa-md-3 mr-0 mr-md-4 mb-4"
                :class="[
                  { 'team-goals-column': breakpoint.mdAndUp },
                  conversionTeamNorwayTotalClass,
                ]"
              >
                <div class="d-flex align-center">
                  <div class="d-flex flex-column">
                    <div class="d-flex justify-center mb-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 512 512"
                      >
                        <mask id="a">
                          <circle cx="256" cy="256" r="256" fill="#fff" />
                        </mask>
                        <g mask="url(#a)">
                          <path
                            fill="#d80027"
                            d="M0 0h100.2l66.1 53.5L233.7 0H512v189.3L466.3 257l45.7 65.8V512H233.7l-68-50.7-65.5 50.7H0V322.8l51.4-68.5-51.4-65z"
                          />
                          <path
                            fill="#eee"
                            d="M100.2 0v189.3H0v33.4l24.6 33L0 289.5v33.4h100.2V512h33.4l30.6-26.3 36.1 26.3h33.4V322.8H512v-33.4l-24.6-33.7 24.6-33v-33.4H233.7V0h-33.4l-33.8 25.3L133.6 0z"
                          />
                          <path
                            fill="#0052b4"
                            d="M133.6 0v222.7H0v66.7h133.6V512h66.7V289.4H512v-66.7H200.3V0z"
                          />
                        </g>
                      </svg>
                    </div>
                    <div>
                      <div class="text-uppercase mb-2">Conversion</div>
                      <div class="text-medium mb-2">
                        Today:
                        <ICountUp
                          class="counter"
                          :delay="2000"
                          :endVal="conversionTeamNorwayToday"
                          :options="{
                            useEasing: true,
                            duration: 10,
                          }"
                        />
                      </div>
                      <div class="text-medium font-weight-black">
                        Total:
                        <ICountUp
                          class="counter"
                          :delay="2300"
                          :endVal="conversionTeamNorwayTotal"
                          :options="{
                            useEasing: true,
                            duration: 10,
                          }"
                          @ready="onConversionTeamNorwayCountReady"
                        />
                        of {{ norwayConversionTeamGoalMonth }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-6 col-md-3 d-flex flex-row justify-center pa-4 pa-md-3 mb-4 mr-0 mr-md-4"
                :class="[
                  { 'team-goals-column': breakpoint.mdAndUp },
                  conversionTeamSwedenTotalClass,
                ]"
              >
                <div class="d-flex align-center">
                  <div class="d-flex flex-column">
                    <div class="d-flex justify-center mb-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 512 512"
                      >
                        <mask id="a">
                          <circle cx="256" cy="256" r="256" fill="#fff" />
                        </mask>
                        <g mask="url(#a)">
                          <path
                            fill="#0052b4"
                            d="M0 0h133.6l35.3 16.7L200.3 0H512v222.6l-22.6 31.7 22.6 35.1V512H200.3l-32-19.8-34.7 19.8H0V289.4l22.1-33.3L0 222.6z"
                          />
                          <path
                            fill="#ffda44"
                            d="M133.6 0v222.6H0v66.8h133.6V512h66.7V289.4H512v-66.8H200.3V0z"
                          />
                        </g>
                      </svg>
                    </div>
                    <div>
                      <div class="text-uppercase mb-2">Conversion</div>
                      <div class="text-medium mb-2">
                        Today:
                        <ICountUp
                          class="counter"
                          :delay="1400"
                          :endVal="conversionTeamSwedenToday"
                          :options="{
                            useEasing: true,
                            duration: 10,
                          }"
                        />
                      </div>
                      <div class="text-medium font-weight-black">
                        Total:
                        <ICountUp
                          class="counter"
                          :delay="460"
                          :endVal="conversionTeamSwedenTotal"
                          :options="{
                            useEasing: true,
                            duration: 10,
                          }"
                          @ready="onConversionTeamSwedenCountReady"
                        />
                        of {{ swedenConversionTeamGoalMonth }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- storkunde sverige start  -->
              <div
                class="col-6 col-md-3 d-flex flex-row justify-center pa-4 pa-md-3 mr-0 mr-md-4 mb-4"
                :class="[
                  { 'team-goals-column': breakpoint.mdAndUp },
                  storkundeTeamSwedenTotalClass,
                ]"
              >
                <div class="d-flex align-center">
                  <div class="d-flex flex-column">
                    <div class="d-flex justify-center mb-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 512 512"
                      >
                        <mask id="a">
                          <circle cx="256" cy="256" r="256" fill="#fff" />
                        </mask>
                        <g mask="url(#a)">
                          <path
                            fill="#0052b4"
                            d="M0 0h133.6l35.3 16.7L200.3 0H512v222.6l-22.6 31.7 22.6 35.1V512H200.3l-32-19.8-34.7 19.8H0V289.4l22.1-33.3L0 222.6z"
                          />
                          <path
                            fill="#ffda44"
                            d="M133.6 0v222.6H0v66.8h133.6V512h66.7V289.4H512v-66.8H200.3V0z"
                          />
                        </g>
                      </svg>
                    </div>
                    <div>
                      <div class="text-uppercase mb-2">Key account</div>
                      <div class="text-medium mb-2">
                        Today:
                        <ICountUp
                          class="counter"
                          :delay="2100"
                          :endVal="storkundeTeamSwedenToday"
                          :options="{
                            useEasing: true,
                            duration: 10,
                          }"
                        />
                      </div>
                      <div class="text-medium font-weight-black">
                        Total:
                        <ICountUp
                          class="counter"
                          :delay="2000"
                          :endVal="storkundeTeamSwedenTotal"
                          :options="{
                            useEasing: true,
                            duration: 4,
                          }"
                          @ready="onStorkundeTeamReady"
                        />
                        of {{ storkundeTeamSwedenGoalMonth }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- storkunde sverige end  -->
              <!-- storkunde norge start  -->
              <div
                class="col-6 col-md-3 d-flex flex-row justify-center pa-4 pa-md-3 mr-0 mr-md-4 mb-4"
                :class="[
                  { 'team-goals-column': breakpoint.mdAndUp },
                  storkundeTeamNorwayTotalClass,
                ]"
              >
                <div class="d-flex align-center">
                  <div class="d-flex flex-column">
                    <div class="d-flex justify-center mb-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 512 512"
                      >
                        <mask id="a">
                          <circle cx="256" cy="256" r="256" fill="#fff" />
                        </mask>
                        <g mask="url(#a)">
                          <path
                            fill="#d80027"
                            d="M0 0h100.2l66.1 53.5L233.7 0H512v189.3L466.3 257l45.7 65.8V512H233.7l-68-50.7-65.5 50.7H0V322.8l51.4-68.5-51.4-65z"
                          />
                          <path
                            fill="#eee"
                            d="M100.2 0v189.3H0v33.4l24.6 33L0 289.5v33.4h100.2V512h33.4l30.6-26.3 36.1 26.3h33.4V322.8H512v-33.4l-24.6-33.7 24.6-33v-33.4H233.7V0h-33.4l-33.8 25.3L133.6 0z"
                          />
                          <path
                            fill="#0052b4"
                            d="M133.6 0v222.7H0v66.7h133.6V512h66.7V289.4H512v-66.7H200.3V0z"
                          />
                        </g>
                      </svg>
                    </div>
                    <div>
                      <div class="text-uppercase mb-2">Key account</div>
                      <div class="text-medium mb-2">
                        Today:
                        <ICountUp
                          class="counter"
                          :delay="2100"
                          :endVal="storkundeTeamNorwayToday"
                          :options="{
                            useEasing: true,
                            duration: 10,
                          }"
                        />
                      </div>
                      <div class="text-medium font-weight-black">
                        Total:
                        <ICountUp
                          class="counter"
                          :delay="2000"
                          :endVal="storkundeTeamNorwayTotal"
                          :options="{
                            useEasing: true,
                            duration: 4,
                          }"
                          @ready="onStorkundeTeamReady"
                        />
                        of {{ storkundeTeamNorwayGoalMonth }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- storkunde norge end  -->
              <!-- forsikring -->
              <div
                class="col-6 col-md-3 d-flex flex-row justify-center pa-4 pa-md-3 mb-4 mr-0 mr-md-4"
                :class="[
                  { 'team-goals-column': breakpoint.mdAndUp },
                  forsikringTeamTotalClass,
                ]"
              >
                <div class="d-flex align-center">
                  <div class="d-flex flex-column">
                    <div class="d-flex justify-center mb-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 512 512"
                      >
                        <mask id="a">
                          <circle cx="256" cy="256" r="256" fill="#fff" />
                        </mask>
                        <g mask="url(#a)">
                          <path
                            fill="#d80027"
                            d="M0 0h100.2l66.1 53.5L233.7 0H512v189.3L466.3 257l45.7 65.8V512H233.7l-68-50.7-65.5 50.7H0V322.8l51.4-68.5-51.4-65z"
                          />
                          <path
                            fill="#eee"
                            d="M100.2 0v189.3H0v33.4l24.6 33L0 289.5v33.4h100.2V512h33.4l30.6-26.3 36.1 26.3h33.4V322.8H512v-33.4l-24.6-33.7 24.6-33v-33.4H233.7V0h-33.4l-33.8 25.3L133.6 0z"
                          />
                          <path
                            fill="#0052b4"
                            d="M133.6 0v222.7H0v66.7h133.6V512h66.7V289.4H512v-66.7H200.3V0z"
                          />
                        </g>
                      </svg>
                    </div>
                    <div>
                      <div class="text-uppercase mb-2">Insurance</div>
                      <div class="text-medium mb-2">
                        Today:
                        <ICountUp
                          class="counter"
                          :delay="200"
                          :endVal="forsikringTeamToday"
                          :options="{
                            useEasing: true,
                            duration: 14,
                          }"
                        />
                      </div>
                      <div class="text-medium font-weight-black">
                        Total:
                        <ICountUp
                          class="counter"
                          :delay="500"
                          :endVal="forsikringTeamTotal"
                          :options="{
                            useEasing: true,
                            duration: 12,
                          }"
                          @ready="onForsikringTeamReady"
                        />
                        of {{ insuranceTeamGoalMonth }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- forsikring end -->
            </div>
          </div>
          <!-- Overordnede team mål END -->

          <div id="charts" class="d-flex flex-column flex-md-row">
            <div class="col-12 col-md-4">
              <div id="c1" class="d-flex align-center justify-center">
                <div
                  :class="
                    breakpoint.smAndDown
                      ? 'counter-inner-container-big'
                      : 'counter-inner-container-small'
                  "
                >
                  <div>
                    <span class="text-center d-block mb-5 vollkorn"
                      >Todays goal:
                      {{ Math.floor(this.salesData.today.target) }}</span
                    >
                  </div>
                  <div class="circle-content">
                    <div :class="['circle-number', dayCircleNumber]">
                      <div v-if="this.salesData.today.units === 0">
                        <span>-</span>
                      </div>
                      <template v-else>
                        <ICountUp
                          class="counter"
                          :delay="0"
                          :endVal="this.salesData.today.units"
                          :options="{
                            useEasing: true,
                            duration: 5,
                          }"
                        />
                      </template>
                    </div>
                    <div class="text-center circle-help-text mt-6">
                      <span>Licences sold today</span>
                    </div>
                  </div>
                  <div
                    ref="chartOne"
                    style="position: relative; bottom: 0px"
                  ></div>
                </div>
                <div style="position: absolute; z-index: 11111">
                  <lottie-animation
                    v-if="todayGoalReached"
                    path="lottie_confetti.json"
                    :width="300"
                    :height="300"
                    :loop="false"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div id="c2" class="d-flex align-center justify-center">
                <div class="counter-inner-container-big">
                  <div class="d-none">
                    <span class="text-center d-block mb-5 vollkorn"
                      >Mål måned: {{ Math.floor(this.goalThisMonth) }}</span
                    >
                  </div>
                  <div class="circle-content">
                    <div :class="['circle-number', monthCircleNumber]">
                      <div v-if="this.salesData.month.units === 0">
                        <span>-</span>
                      </div>
                      <template v-else>
                        <ICountUp
                          class="counter"
                          :delay="0"
                          :endVal="this.salesData.month.units"
                          :options="{
                            useEasing: true,
                            duration: 5,
                          }"
                        />
                      </template>
                    </div>
                    <div class="text-center circle-help-text mt-6">
                      <span>Licences sold in {{ thisMonth }}</span>
                    </div>
                  </div>
                  <div
                    ref="chartTwo"
                    style="position: relative; bottom: 0px"
                  ></div>
                </div>
                <div style="position: absolute; z-index: 11111">
                  <lottie-animation
                    v-if="monthGoalReached"
                    path="lottie_confetti.json"
                    :width="300"
                    :height="300"
                    :loop="false"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div id="c3" class="d-flex align-center justify-center">
                <div
                  :class="
                    breakpoint.smAndDown
                      ? 'counter-inner-container-big'
                      : 'counter-inner-container-small'
                  "
                >
                  <div>
                    <span class="text-center d-block mb-5 vollkorn"
                      >Goal this week:
                      {{ Math.floor(this.salesData.week.target) }}</span
                    >
                  </div>
                  <div class="circle-content">
                    <div :class="['circle-number', weekCircleNumber]">
                      <div v-if="this.salesData.week.units === 0">
                        <span>-</span>
                      </div>
                      <template v-else>
                        <ICountUp
                          class="counter"
                          :delay="0"
                          :endVal="this.salesData.week.units"
                          :options="{
                            useEasing: true,
                            duration: 5,
                          }"
                        />
                      </template>
                    </div>
                    <div class="text-center circle-help-text mt-6">
                      <span>Licences sold week {{ weekNumber }}</span>
                    </div>
                  </div>
                  <div
                    ref="chartThree"
                    style="position: relative; bottom: 0px"
                  ></div>
                </div>
                <div style="position: absolute; z-index: 11111">
                  <lottie-animation
                    v-if="weekGoalReached"
                    path="lottie_confetti.json"
                    :width="300"
                    :height="300"
                    :loop="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </v-main>

      <v-container>
        <v-row grid-list-md>
          <v-col cols="12" md="6" class="mb-15">
            <span class="sub-header text-center">Sellers of the Month</span>
            <v-simple-table>
              <template
                v-if="filteredSellersOfTheMonth.length > 0"
                v-slot:default
              >
                <tbody>
                  <tr
                    :class="{ 'top-position-bg': index === 0 }"
                    v-for="(monthly, index) in filteredSellersOfTheMonth"
                    :key="index"
                  >
                    <td class="pa-0">
                      <div class="d-flex justify-end">
                        <lottie-animation
                          v-if="index === 0"
                          style="margin: 0"
                          path="lottie_star.json"
                          :width="30"
                          :height="30"
                          :loop="true"
                          class="mr-2"
                        />
                      </div>
                    </td>
                    <td>{{ index + 1 }}</td>
                    <td>
                      <span class="table-name">{{ monthly.name }}</span>
                    </td>
                    <td class="text-center">{{ parseInt(monthly.units) }}</td>
                  </tr>
                </tbody>
              </template>
              <template v-else v-slot:default>
                <div class="text-center">No data available 😥</div>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" md="6" class="mb-15">
            <span class="sub-header text-center">Bookers of the Month</span>
            <v-simple-table>
              <template
                v-if="filteredBookersOfTheMonth.length > 0"
                v-slot:default
              >
                <tbody>
                  <tr
                    :class="{ 'top-position-bg': index === 0 }"
                    v-for="(monthly, index) in filteredBookersOfTheMonth"
                    :key="index"
                  >
                    <td class="pa-0">
                      <div class="d-flex justify-end">
                        <lottie-animation
                          v-if="index === 0"
                          style="margin: 0"
                          path="lottie_star.json"
                          :width="30"
                          :height="30"
                          :loop="true"
                          class="mr-2"
                        />
                      </div>
                    </td>
                    <td>{{ index + 1 }}</td>
                    <td>
                      <span class="table-name">{{ monthly.name }}</span>
                    </td>
                    <td class="text-center">{{ parseInt(monthly.units) }}</td>
                  </tr>
                </tbody>
              </template>
              <template v-else v-slot:default>
                <div class="text-center">No data available 😥</div>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
      <!-- Månedens selgere og bookere storkunde -->
      <v-container style="padding-top: 40px">
        <v-row grid-list-md>
          <v-col cols="12" md="6" class="mb-15">
            <span class="sub-header text-center"
              >Key Account sellers of the Month</span
            >
            <v-simple-table>
              <template
                v-slot:default
                v-if="filteredKeyAccountSellersOfTheMonth.length > 0"
              >
                <tbody>
                  <tr
                    :class="{ 'top-position-bg': index === 0 }"
                    v-for="(
                      storkundeSellersThisMonth, index
                    ) in filteredKeyAccountSellersOfTheMonth"
                    :key="index"
                  >
                    <td class="pa-0">
                      <div class="d-flex justify-end">
                        <lottie-animation
                          v-if="index === 0"
                          style="margin: 0"
                          path="lottie_star.json"
                          :width="30"
                          :height="30"
                          :loop="true"
                          class="mr-2"
                        />
                      </div>
                    </td>
                    <td>{{ index + 1 }}</td>
                    <td>
                      <span class="table-name">
                        {{ storkundeSellersThisMonth.name }}</span
                      >
                    </td>
                    <td class="text-center">
                      {{ parseInt(storkundeSellersThisMonth.units) }}
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-else v-slot:default>
                <div class="text-center">No data available 😥</div>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" md="6" class="mb-15">
            <span class="sub-header text-center"
              >Key Account bookers of the Month</span
            >
            <v-simple-table>
              <template
                v-if="filteredKeyAccountBookersOfTheMonth.length > 0"
                v-slot:default
              >
                <tbody>
                  <tr
                    :class="{ 'top-position-bg': index === 0 }"
                    v-for="(
                      storkundeBookersThisMonth, index
                    ) in filteredKeyAccountBookersOfTheMonth"
                    :key="index"
                  >
                    <td class="pa-0">
                      <div class="d-flex justify-end">
                        <lottie-animation
                          v-if="index === 0"
                          style="margin: 0"
                          path="lottie_star.json"
                          :width="30"
                          :height="30"
                          :loop="true"
                          class="mr-2"
                        />
                      </div>
                    </td>
                    <td>{{ index + 1 }}</td>
                    <td>
                      <span class="table-name">{{
                        storkundeBookersThisMonth.name
                      }}</span>
                    </td>
                    <td class="text-center">
                      {{ parseInt(storkundeBookersThisMonth.units) }}
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-else v-slot:default>
                <div class="text-center">No data available 😥</div>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
      <!-- Månedend selgere og bookere storkunde END -->
      <v-container>
        <v-row grid-list-md>
          <v-col cols="12" md="6" class="mb-15">
            <span class="sub-header text-center">Sellers of the Week</span>
            <v-simple-table>
              <template
                v-if="filteredSellersOfTheWeek.length > 0"
                v-slot:default
              >
                <tbody>
                  <tr
                    :class="{ 'top-position-bg': index === 0 }"
                    v-for="(weeklySeller, index) in filteredSellersOfTheWeek"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="pa-0">
                      <div class="d-flex justify-end">
                        <lottie-animation
                          v-if="index === 0"
                          style="margin: 0"
                          path="lottie_star.json"
                          :width="30"
                          :height="30"
                          :loop="true"
                          class="mr-2"
                        />
                      </div>
                    </td>
                    <td>
                      <span class="table-name">{{ weeklySeller.name }}</span>
                    </td>
                    <td class="text-center">
                      {{ parseInt(weeklySeller.units) }}
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-else v-slot:default>
                <div class="text-center">No data available 😥</div>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" md="6" class="mb-15">
            <span class="sub-header text-center">Bookers of the Week</span>
            <v-simple-table>
              <template
                v-if="filteredBookersOfTheWeek.length > 0"
                v-slot:default
              >
                <tbody>
                  <tr
                    :class="{ 'top-position-bg': index === 0 }"
                    v-for="(weeklyBooker, index) in filteredBookersOfTheWeek"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="pa-0">
                      <div class="d-flex justify-end">
                        <lottie-animation
                          v-if="index === 0"
                          style="margin: 0"
                          path="lottie_star.json"
                          :width="30"
                          :height="30"
                          :loop="true"
                          class="mr-2"
                        />
                      </div>
                    </td>
                    <td>
                      <span class="table-name">{{ weeklyBooker.name }}</span>
                      <img
                        v-if="weeklyBooker.status != null"
                        :src="'/assets/' + getPic(weeklyBooker.landCode)"
                        class="flag-icon"
                      />
                    </td>
                    <td class="text-center">
                      {{ parseInt(weeklyBooker.units) }}
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-else v-slot:default>
                <div class="text-center">No data available 😥</div>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>

      <!-- Dagens booker og dagens selger -->
      <v-container style="padding-top: 40px">
        <v-row grid-list-md>
          <v-col cols="12" md="6" class="mb-15">
            <span class="sub-header text-center">Sellers of the Day</span>
            <v-simple-table>
              <template
                v-if="filteredSellersOfTheDay.length > 0"
                v-slot:default
              >
                <tbody>
                  <tr
                    :class="{ 'top-position-bg': index === 0 }"
                    v-for="(sellersToday, index) in filteredSellersOfTheDay"
                    :key="index"
                  >
                    <td class="pa-0">
                      <div class="d-flex justify-end">
                        <lottie-animation
                          v-if="index === 0"
                          style="margin: 0"
                          path="lottie_star.json"
                          :width="30"
                          :height="30"
                          :loop="true"
                          class="mr-2"
                        />
                      </div>
                    </td>
                    <td>
                      {{ index + 1 }}
                    </td>
                    <td>
                      <span class="table-name"> {{ sellersToday.name }}</span>
                    </td>
                    <td class="text-center">
                      {{ parseInt(sellersToday.units) }}
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-else v-slot:default>
                <div class="text-center">No data available 😥</div>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" md="6" class="mb-15">
            <span class="sub-header text-center">Bookers of the Day</span>
            <v-simple-table>
              <template
                v-if="filteredBookersOfTheDay.length > 0"
                v-slot:default
              >
                <tbody>
                  <tr
                    :class="{ 'top-position-bg': index === 0 }"
                    v-for="(bookersToday, index) in filteredBookersOfTheDay"
                    :key="index"
                  >
                    <td class="pa-0">
                      <div class="d-flex justify-end">
                        <lottie-animation
                          v-if="index === 0"
                          style="margin: 0"
                          path="lottie_star.json"
                          :width="30"
                          :height="30"
                          :loop="true"
                          class="mr-2"
                        />
                      </div>
                    </td>
                    <td>{{ index + 1 }}</td>
                    <td>
                      <span class="table-name">{{ bookersToday.name }}</span>
                    </td>
                    <td class="text-center">
                      {{ parseInt(bookersToday.units) }}
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-else v-slot:default>
                <div class="text-center">No data available 😥</div>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
      <!-- Dagens booker og dagens selger END -->
      <!-- Week and month health insurance -->
      <v-container style="padding-top: 40px">
        <v-row grid-list-md>
          <v-col cols="12" md="6" class="mb-15">
            <span class="sub-header text-center"
              >Insurance Sellers of the Week</span
            >
            <v-simple-table>
              <template
                v-if="filteredInsuranceSellersOfTheWeek.length > 0"
                v-slot:default
              >
                <tbody>
                  <tr
                    :class="{ 'top-position-bg': index === 0 }"
                    v-for="(
                      insuranceSellersThisWeek, index
                    ) in filteredInsuranceSellersOfTheWeek"
                    :key="index"
                  >
                    <td class="pa-0">
                      <div class="d-flex justify-end">
                        <lottie-animation
                          v-if="index === 0"
                          style="margin: 0"
                          path="lottie_star.json"
                          :width="30"
                          :height="30"
                          :loop="true"
                          class="mr-2"
                        />
                      </div>
                    </td>
                    <td>{{ index + 1 }}</td>
                    <td>
                      <span class="table-name">
                        {{ insuranceSellersThisWeek.name }}</span
                      >
                    </td>
                    <td class="text-center">
                      {{
                        parseInt(
                          insuranceSellersThisWeek.customNumbers.insuranceUnits
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-else v-slot:default>
                <div class="text-center">No data available 😥</div>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" md="6" class="mb-15">
            <span class="sub-header text-center"
              >Insurance Sellers of the Month</span
            >
            <v-simple-table>
              <template
                v-if="filteredInsuranceSellersOfTheMonth.length > 0"
                v-slot:default
              >
                <tbody>
                  <tr
                    :class="{ 'top-position-bg': index === 0 }"
                    v-for="(
                      insuranceSellersThisMonth, index
                    ) in filteredInsuranceSellersOfTheMonth"
                    :key="index"
                  >
                    <td class="pa-0">
                      <div class="d-flex justify-end">
                        <lottie-animation
                          v-if="index === 0"
                          style="margin: 0"
                          path="lottie_star.json"
                          :width="30"
                          :height="30"
                          :loop="true"
                          class="mr-2"
                        />
                      </div>
                    </td>
                    <td>{{ index + 1 }}</td>
                    <td>
                      <span class="table-name">{{
                        insuranceSellersThisMonth.name
                      }}</span>
                    </td>
                    <td class="text-center">
                      {{
                        parseInt(
                          insuranceSellersThisMonth.customNumbers.insuranceUnits
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-else v-slot:default>
                <div class="text-center">No data available 😥</div>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
      <!-- Dagens booker og dagens selger END -->

      <!-- Månedens selgere og bookere Grenland BHT -->

      <!--
      <v-container style="padding-top: 40px">
        <v-row grid-list-md>
          <v-col cols="12" md="6" class="mb-15">
            <span class="sub-header text-center"
              >Månedens selgere Grenland BHT</span
            >
            <v-simple-table>
              <template
                v-slot:default
                v-if="grenlandBhtSellersMonth.length > 0"
              >
                <tbody>
                  <tr
                    :class="{ 'top-position-bg': index === 0 }"
                    v-for="(
                      grenlandBhtSellersThisMonth, index
                    ) in grenlandBhtSellersMonth"
                    :key="index"
                  >
                    <td class="pa-0">
                      <div class="d-flex justify-end">
                        <lottie-animation
                          v-if="index === 0"
                          style="margin: 0"
                          path="lottie_star.json"
                          :width="30"
                          :height="30"
                          :loop="true"
                          class="mr-2"
                        />
                      </div>
                    </td>
                    <td>{{ index + 1 }}</td>
                    <td>
                      <span class="table-name">
                        {{ grenlandBhtSellersThisMonth.name }}</span
                      >
                    </td>
                    <td class="text-center">
                      {{ grenlandBhtSellersThisMonth.units }}
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-else v-slot:default>
                <div class="text-center">No data available 😥</div>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" md="6" class="mb-15">
            <span class="sub-header text-center"
              >Månedens bookere Grenland BHT</span
            >
            <v-simple-table>
              <template
                v-if="grenlandBhtBookersMonth.length > 0"
                v-slot:default
              >
                <tbody>
                  <tr
                    :class="{ 'top-position-bg': index === 0 }"
                    v-for="(
                      grenlandBhtBookersThisMonth, index
                    ) in grenlandBhtBookersMonth"
                    :key="index"
                  >
                    <td class="pa-0">
                      <div class="d-flex justify-end">
                        <lottie-animation
                          v-if="index === 0"
                          style="margin: 0"
                          path="lottie_star.json"
                          :width="30"
                          :height="30"
                          :loop="true"
                          class="mr-2"
                        />
                      </div>
                    </td>
                    <td>{{ index + 1 }}</td>
                    <td>
                      <span class="table-name">{{
                        grenlandBhtBookersThisMonth.name
                      }}</span>
                    </td>
                    <td class="text-center">
                      {{ grenlandBhtBookersThisMonth.units }}
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-else v-slot:default>
                <div class="text-center">No data available 😥</div>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>-->
      <!-- Månedend selgere og bookere Grenland BHT END -->
      <div id="stat-bar" class="text-center py-md-10">
        <v-row no-gutters>
          <v-col cols="12" md="6" lg="3" class="py-6 py-md-0 my-md-6">
            <div class="price pt-3">
              {{ this.salesData.avgUnitsPerCustomer.toFixed(0) }}
            </div>
            <div class="price-text mt-6">AVERAGE CUSTOMER SIZE</div>
          </v-col>
          <v-col cols="12" md="6" lg="3" class="py-6 py-md-0 my-md-6">
            <div class="price pt-3">
              {{ this.salesData.avgUnitPrice.toFixed(0) }},-
            </div>
            <div class="price-text mt-6">AVERAGE LICENSE PRICE</div>
          </v-col>
          <v-col cols="12" md="6" lg="3" class="py-6 py-md-0 my-md-6">
            <div class="price pt-3">
              {{ this.salesData.yearlyInvoicePercent.toFixed(0) }}%
            </div>
            <div class="price-text mt-6">ANNUAL INVOICE PERCENT</div>
          </v-col>
          <v-col cols="12" md="6" lg="3" class="py-6 py-md-0 my-md-6">
            <div class="price pt-3">{{ this.salesData.month.customers }}</div>
            <div class="price-text mt-6">
              NEW CONTRACTS IN {{ thisMonth.toUpperCase() }}
            </div>
          </v-col>
        </v-row>
      </div>

      <div id="top-bar-one" class="text-center pt-2 pb-3">
        <v-container><Logo width="110" /> </v-container>
      </div>
    </div>

    <div v-else>
      <v-overlay color="avonovaMidGreen" opacity="0.1">
        <v-progress-circular
          indeterminate
          size="64"
          :width="7"
          color="avonovaDarkGreen"
        ></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>
<script>
import Logo from "../components/Logo";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import ICountUp from "vue-countup-v2";
var ProgressBar = require("progressbar.js");
export default {
  name: "Home",
  components: {
    ICountUp,
    LottieAnimation,
    Logo,
  },
  data() {
    return {
      showLeadMenu: false,
      norwayTeamCountReady: false,
      swedenTeamCountReady: false,
      conversionTeamNorwayCountReady: false,
      conversionTeamSwedenCountReady: false,
      storkundeTeamNorwayAndSwedenCountReady: false,
      storkundeTeamSwedenCountReady: false,
      forsikringTeamCountReady: false,
      finlandTeamCountReady: false,
      isAdmin: false,
      newDateSelected: false,
      d: new Date().toISOString().substr(0, 10),
      calendar: false,
      dayCircleNumber: "circle-red",
      monthCircleNumber: "circle-red",
      weekCircleNumber: "circle-red",
      todayGoalReached: false,
      monthGoalReached: false,
      weekGoalReached: false,
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      isLoaded: false,
      counterOptions: {
        useEasing: true,
        duration: 8,
      },
      salesData: null,
    };
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 70) {
        this.isAdmin = !this.isAdmin;
      }
    });
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 80) {
        this.calendar = !this.calendar;
      }
    });
    this.createCharts();
  },
  methods: {
    onNorwayReady() {
      this.norwayTeamCountReady = true;
    },
    onSwedenReady() {
      this.swedenTeamCountReady = true;
    },
    onFinlandReady() {
      this.finlandTeamCountReady = true;
    },
    onConversionTeamNorwayCountReady() {
      this.conversionTeamNorwayCountReady = true;
    },
    onConversionTeamSwedenCountReady() {
      this.conversionTeamSwedenCountReady = true;
    },
    onStorkundeTeamReady() {
      this.storkundeTeamNorwayAndSwedenCountReady = true;
    },
    onForsikringTeamReady() {
      this.forsikringTeamCountReady = true;
    },
    swipe(direction) {
      if (direction === "left") this.isAdmin = true;
      if (direction === "right") this.isAdmin = false;
    },
    pickNewDate(d) {
      this.isLoaded = false;
      this.salesData = null;
      this.$router.push({
        name: "Home",
        params: { date: d },
      });
      this.calendar = false;
      this.newDateSelected = false;
    },
    async createCharts() {
      await this.getSalesData().then(() => {
        this.$refs.chartOne.innerHTML = "";
        this.$refs.chartTwo.innerHTML = "";
        this.$refs.chartThree.innerHTML = "";
        this.todayGoalReached = false;
        this.monthGoalReached = false;
        this.weekGoalReached = false;
        this.dayCircleNumber = "circle-red";
        this.monthCircleNumber = "circle-red";
        this.weekCircleNumber = "circle-red";
        const chartOne = this.$refs.chartOne;
        const chartTwo = this.$refs.chartTwo;
        const chartThree = this.$refs.chartThree;

        const vueData = this;

        const createChartOne = new ProgressBar.Circle(chartOne, {
          color: "#ff6c62",
          duration: 4200,
          easing: "easeInOut",
          trailColor: "#ffbaae",
          strokeWidth: 6,
          from: { color: "#FF6C62", prog: 0 },
          to: { color: "#27ddbb", prog: 1 },
          step: function (state, circle) {
            if (state.prog === 1) {
              vueData.todayGoalReached = true;
              circle.path.setAttribute("stroke", state.color);
            }
          },
        });

        createChartOne.animate(
          this.process(this.salesData.today.units, this.salesData.today.target)
        );

        const createChartTwo = new ProgressBar.Circle(chartTwo, {
          color: "#ff6c62",
          duration: 4100,
          easing: "easeInOut",
          trailColor: "#ffbaae",
          strokeWidth: 6,
          from: { color: "#FF6C62", prog: 0 },
          to: { color: "#27ddbb", prog: 1 },
          step: function (state, circle) {
            if (state.prog === 1) {
              vueData.monthGoalReached = true;
              circle.path.setAttribute("stroke", state.color);
            }
          },
        });

        createChartTwo.animate(
          this.process(this.salesData.month.units, this.goalThisMonth)
        );

        const createChartThree = new ProgressBar.Circle(chartThree, {
          color: "#ff6c62",
          duration: 4600,
          easing: "easeInOut",
          trailColor: "#ffbaae",
          strokeWidth: 6,
          from: { color: "#ff6c62", prog: 0 },
          to: { color: "#27ddbb", prog: 1 },
          step: function (state, circle) {
            if (state.prog === 1) {
              vueData.weekGoalReached = true;
              circle.path.setAttribute("stroke", state.color);
            }
          },
        });

        createChartThree.animate(
          this.process(this.salesData.week.units, this.salesData.week.target)
        );
      });
    },
    generateQueryDate() {
      const todaysDate = new Date();
      const setDate = todaysDate.toISOString().split("T")[0];
      return setDate;
    },
    process(unit, target) {
      if (unit === 0 && target === 0) return 0;
      target = ~~target;
      if (unit >= target) {
        return 1;
      } else {
        const calc = (unit / target) * 100;
        return parseFloat(calc.toFixed(2)) / 100;
      }
    },
    async getSalesData() {
      await this.$store
        .dispatch("getSalesData", {
          ax: this.$http,
          date: this.$route.params.date,
        })
        .then((res) => {
          this.salesData = res.data;
        })
        .then(() => {
          if (this.salesData.today.units != 0.0) {
            let todayNoTeamSales = 0;
            this.salesData.today.agents.map((sold) => {
              todayNoTeamSales += sold.units;
            });

            if (this.salesData.today.units != todayNoTeamSales) {
              this.salesData.today.agents.push({
                status: null,
                position: null,
                name: "No Team",
                landCode: null,
                units: this.salesData.today.units - todayNoTeamSales,
              });
            }
          }
          if (this.salesData.week.units != 0.0) {
            let weekNoTeamSales = 0;
            this.salesData.week.agents.map((sold) => {
              weekNoTeamSales += sold.units;
            });

            if (this.salesData.week.units != weekNoTeamSales) {
              this.salesData.week.agents.push({
                status: null,
                position: null,
                name: "No Team",
                landCode: null,
                units: this.salesData.week.units - weekNoTeamSales,
              });
            }
          }
          if (this.salesData.month.units != 0.0) {
            let monthNoTeamSales = 0;
            this.salesData.month.agents.map((sold) => {
              monthNoTeamSales += sold.units;
            });
            if (this.salesData.month.units != monthNoTeamSales) {
              this.salesData.month.agents.push({
                status: null,
                position: null,
                name: "No Team",
                landCode: null,
                units: this.salesData.month.units - monthNoTeamSales,
              });
            }
          }
        })
        .then((this.isLoaded = true));
    },
    getPic(pic) {
      if (pic === "no") return pic + ".png";
      if (pic === "se") return pic + ".png";
    },
    getIcon(status) {
      if (status === 1) return "fas fa-caret-up";
      if (status === -1) return "fas fa-caret-down";
      if (status === 0) return "fas fa-grip-lines";
    },
    isAppleDevice: function () {
      if (navigator.userAgent.match(/(iPhone|iPod|iPad)/) != null) {
        return true;
      }
      return false;
    },
  },
  computed: {
    filteredBookersOfTheWeek() {
      return this.salesData.week.bookers.filter(
        (figure) =>
          figure.mainTeamId !== 265 &&
          figure.mainTeamId !== 253 &&
          figure.mainTeamId !== 207 &&
          figure.mainTeamId !== 209 &&
          figure.mainTeamId !== 269
      );
    },
    filteredSellersOfTheWeek() {
      return this.salesData.week.sellers.filter(
        (figure) =>
          figure.units > 0 &&
          figure.mainTeamId !== 265 &&
          figure.mainTeamId !== 253 &&
          figure.mainTeamId !== 207 &&
          figure.mainTeamId !== 209 &&
          figure.mainTeamId !== 269
      );
    },
    filteredKeyAccountBookersOfTheMonth() {
      return this.salesData.month.bookers
        .filter(
          (salesFigure) =>
            salesFigure.mainTeamId === 265 || salesFigure.mainTeamId === 253
        )
        .sort((a, b) => b.units - a.units);
    },
    filteredKeyAccountSellersOfTheMonth() {
      return this.salesData.month.sellers
        .filter(
          (salesFigure) =>
            salesFigure.mainTeamId === 265 || salesFigure.mainTeamId === 253
        )
        .sort((a, b) => b.units - a.units);
    },
    filteredBookersOfTheMonth() {
      return this.salesData.month.bookers.filter(
        (figure) =>
          figure.mainTeamId !== 265 &&
          figure.mainTeamId !== 253 &&
          figure.mainTeamId !== 207 &&
          figure.mainTeamId !== 209 &&
          figure.mainTeamId !== 269
      );
    },
    filteredInsuranceSellersOfTheWeek() {
      if (this.salesData.week.sellers.length === 0) {
        return [];
      }
      return this.salesData.week.sellers
        .filter(
          (figure) =>
            figure.customNumbers.insuranceUnits > 0 &&
            figure.mainTeamId !== 265 &&
            figure.mainTeamId !== 253
        )
        .sort(
          (a, b) =>
            b.customNumbers.insuranceUnits - a.customNumbers.insuranceUnits
        );
    },
    filteredInsuranceSellersOfTheMonth() {
      if (this.salesData.month.sellers.length === 0) {
        return [];
      }
      return this.salesData.month.sellers
        .filter(
          (salesData) =>
            salesData.customNumbers.insuranceUnits > 0 &&
            salesData.mainTeamId !== 265 &&
            salesData.mainTeamId !== 253
        )
        .sort(
          (a, b) =>
            b.customNumbers.insuranceUnits - a.customNumbers.insuranceUnits
        );
    },
    filteredSellersOfTheMonth() {
      return this.salesData.month.sellers.filter(
        (figure) =>
          figure.units > 0 &&
          figure.mainTeamId !== 265 &&
          figure.mainTeamId !== 253 &&
          figure.mainTeamId !== 207 &&
          figure.mainTeamId !== 209 &&
          figure.mainTeamId !== 269
      );
    },
    filteredSellersOfTheDay() {
      return this.salesData.today.sellers.filter(
        (figure) =>
          figure.units > 0 &&
          figure.mainTeamId !== 207 &&
          figure.mainTeamId !== 209 &&
          figure.mainTeamId !== 269
      );
    },
    filteredBookersOfTheDay() {
      return this.salesData.today.bookers.filter(
        (figure) =>
          figure.units > 0 &&
          figure.mainTeamId !== 207 &&
          figure.mainTeamId !== 209 &&
          figure.mainTeamId !== 269
      );
    },
    norwayTeamGoalMonth() {
      return this.salesData.month.teams.find((team) => team.id === 124).target;
    },
    swedenTeamGoalMonth() {
      return this.salesData.month.teams.find((team) => team.id === 125).target;
    },
    finlandTeamGoalMonth() {
      return this.salesData.month.teams.find((team) => team.id === 285).target;
    },
    norwayConversionTeamGoalMonth() {
      return this.salesData.month.teams.find((team) => team.id === 193).target;
    },
    swedenConversionTeamGoalMonth() {
      return this.salesData.month.teams.find((team) => team.id === 201).target;
    },
    insuranceTeamGoalMonth() {
      return (
        this.salesData.month.teams.find((team) => team.id === 207).target +
        this.salesData.month.teams.find((team) => team.id === 209).target
      );
    },
    goalThisMonth() {
      return this.salesData.month.teams
        .filter((team) => team.id !== 207 && team.id !== 209)
        .map((team) => team.target)
        .reduce((prev, next) => prev + next);
    },
    norwayTeamTotalClass() {
      return this.norwayTeamCountReady &&
        this.norwayTeamTotal ===
          this.salesData.month.teams.find((team) => team.id === 124).target
        ? "goal-reached"
        : "goal-not-reached";
    },
    swedenTeamTotalClass() {
      return this.swedenTeamCountReady &&
        this.swedenTeamTotal ===
          this.salesData.month.teams.find((team) => team.id === 125).target
        ? "goal-reached"
        : "goal-not-reached";
    },
    finlandTeamTotalClass() {
      return this.finlandTeamCountReady &&
        this.finlandTeamTotal ===
          this.salesData.month.teams.find((team) => team.id === 285).target
        ? "goal-reached"
        : "goal-not-reached";
    },
    conversionTeamNorwayTotalClass() {
      return this.conversionTeamNorwayCountReady &&
        this.conversionTeamNorwayTotal ===
          this.salesData.month.teams.find((team) => team.id === 193).target
        ? "goal-reached"
        : "goal-not-reached";
    },
    conversionTeamSwedenTotalClass() {
      return this.conversionTeamSwedenCountReady &&
        this.conversionTeamSwedenTotal ===
          this.salesData.month.teams.find((team) => team.id === 201).target
        ? "goal-reached"
        : "goal-not-reached";
    },
    forsikringTeamTotalClass() {
      return this.forsikringTeamCountReady &&
        this.forsikringTeamTotal ===
          this.salesData.month.teams.find((team) => team.id === 207).target +
            this.salesData.month.teams.find((team) => team.id === 209).target
        ? "goal-reached"
        : "goal-not-reached";
    },
    conversionTeamNorwayTotal() {
      return this.salesData.month.teams.find((team) => team.id === 193).units;
    },
    conversionTeamSwedenTotal() {
      return this.salesData.month.teams.find((team) => team.id === 201).units;
    },
    norwayTeamTotal() {
      return this.salesData.month.teams.find((team) => team.id === 124).units;
    },
    swedenTeamTotal() {
      return this.salesData.month.teams.find((team) => team.id === 125).units;
    },
    finlandTeamTotal() {
      return this.salesData.month.teams.find((team) => team.id === 285).units;
    },
    forsikringTeamTotal() {
      return (
        this.salesData.month.teams.find((team) => team.id === 207).customNumbers
          .insuranceUnits +
        this.salesData.month.teams.find((team) => team.id === 209).customNumbers
          .insuranceUnits
      );
    },
    storkundeTeamNorwayToday() {
      return this.salesData.today.teams.find((team) => team.id === 253).units;
    },
    storkundeTeamNorwayTotal() {
      return this.salesData.month.teams.find((team) => team.id === 253).units;
    },
    storkundeTeamNorwayGoalMonth() {
      return this.salesData.month.teams.find((team) => team.id === 253).target;
    },
    storkundeTeamNorwayTotalClass() {
      return this.storkundeTeamNorwayCountReady &&
        this.storkundeTeamNorwayTotal === this.storkundeTeamNorwayGoalMonth
        ? "goal-reached"
        : "goal-not-reached";
    },
    storkundeTeamSwedenToday() {
      return this.salesData.today.teams.find((team) => team.id === 265).units;
    },
    storkundeTeamSwedenTotal() {
      return this.salesData.month.teams.find((team) => team.id === 265).units;
    },
    storkundeTeamSwedenGoalMonth() {
      return this.salesData.month.teams.find((team) => team.id === 265).target;
    },
    storkundeTeamSwedenTotalClass() {
      return this.storkundeTeamNorwayCountReady &&
        this.storkundeTeamSwedenTotal === this.storkundeTeamSwedenGoalMonth
        ? "goal-reached"
        : "goal-not-reached";
    },
    forsikringTeamToday() {
      return (
        this.salesData.today.teams.find((team) => team.id === 207).customNumbers
          .insuranceUnits +
        this.salesData.today.teams.find((team) => team.id === 209).customNumbers
          .insuranceUnits
      );
    },
    conversionTeamNorwayToday() {
      return this.salesData.today.teams.find((team) => team.id === 193).units;
    },
    conversionTeamSwedenToday() {
      return this.salesData.today.teams.find((team) => team.id === 201).units;
    },
    norwayTeamToday() {
      return this.salesData.today.teams.find((team) => team.id === 124).units;
    },
    swedenTeamToday() {
      return this.salesData.today.teams.find((team) => team.id === 125).units;
    },
    finlandTeamToday() {
      return this.salesData.today.teams.find((team) => team.id === 285).units;
    },
    calendarDate: {
      get: () => {
        return this.$route.params.date;
      },
      set: (newValue) => {
        this.d = newValue;
      },
    },
    getReadableDate() {
      const date = new Date(this.salesData.date);

      const day = date.getDate();
      const month = this.monthNames[date.getMonth()];

      // Add ordinal suffix
      const getDayWithSuffix = (day) => {
        if (day > 3 && day < 21) return day + "th";
        switch (day % 10) {
          case 1:
            return day + "st";
          case 2:
            return day + "nd";
          case 3:
            return day + "rd";
          default:
            return day + "th";
        }
      };

      return `${getDayWithSuffix(day)} of ${month}`;
    },
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
    weekNumber() {
      Date.prototype.getWeek = function () {
        var jan = new Date(this.getFullYear(), 0, 1);
        return Math.ceil(((this - jan) / 86400000 + jan.getDay() + 1) / 7);
      };
      return new Date().getWeek();
    },
    thisMonth() {
      var date = new Date(this.salesData.date);
      return `${this.monthNames[date.getMonth()]}`;
    },
    grenlandBhtSellersMonth() {
      return [...this.salesData.month.sellers]
        .filter((salesFigure) => salesFigure.mainTeamId === 293)
        .sort((a, b) => b.units - a.units);
    },
    grenlandBhtBookersMonth() {
      return [...this.salesData.month.bookers]
        .filter((salesFigure) => salesFigure.mainTeamId === 317)
        .sort((a, b) => b.units - a.units);
    },
  },
  watch: {
    $route() {
      this.createCharts();
    },
    todayGoalReached(newVal) {
      if (newVal) this.dayCircleNumber = "circle-green";
    },
    monthGoalReached(newVal) {
      if (newVal) this.monthCircleNumber = "circle-green";
    },
    weekGoalReached(newVal) {
      if (newVal) this.weekCircleNumber = "circle-green";
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";
#app {
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  color: #00211b;
}
.text-medium {
  font-size: 1.3em;
}
ul#list-menu {
  padding: 0;
  list-style-type: none;
  background: white;
  li:hover {
    background: #005646;
    a {
      color: white;
    }
  }
  svg {
    margin-right: 10px;
  }
  a {
    padding: 10px;
    text-decoration: none;
    &.hover {
      color: white;
    }
  }
}
#team-goals {
  .team-goals-column {
    &.goal-not-reached {
      border: 2px dashed #dfdfdf;
    }
    &.goal-reached {
      border: 2px solid #27ddbb;
    }
    border-radius: 10px;
    .team-flag {
      border-radius: 25px;
    }
  }
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1em;
  border-bottom: 1px #d8e6e2 solid !important;
}
.vollkorn {
  font-family: "Vollkorn", serif;
}
#top-bar-one {
  background: #00211b;
  font-weight: 800;
  font-size: 2.3em;
}
#top-bar-two {
  background: #005646;
  font-weight: 400;
  color: white;
  font-size: 1.2em;
}
.circle-number {
  font-size: 4.5em;
  font-weight: 800;
  text-align: center;
}
.circle-green {
  color: #27ddbb;
}
.circle-red {
  color: #ff6c62;
}
.table-header {
  font-size: 1.2em !important;
  font-weight: 500;
}
.constrainer {
  width: 100%;
  margin: 0;
  @media (min-width: 960px) {
    width: 70%;
    margin: 0 auto;
  }
}
.monthly-header {
  background: #005646;
  color: white;
  height: 50px;
  font-size: 1.6em;
  line-height: 1.3em;
  font-family: "Vollkorn", serif;
  .trophy {
    font-size: 3.4em;
    height: 160px;
    .trophy-inner {
      animation-duration: 2s;
      animation-iteration-count: infinite;
      transform-origin: bottom;
    }
    &.trophy-1 {
      position: relative;
      left: 0px;
      bottom: 75px;
      transform: rotate(-10deg);
    }
    &.trophy-2 {
      position: relative;
      right: 0px;
      bottom: 75px;
      transform: rotate(10deg);
    }
  }
}
.table-name {
  font-weight: 600;
}
.top-position-bg {
  background-color: #f0f4f3;
}
#stat-bar {
  background: #faf7f6;
  color: #00211b;
  .price {
    font-size: 3.4em;
    font-weight: 600;
  }
  .price-text {
    font-size: 0.9em;
  }
}
.circle-help-text {
  font-size: 0.7em;
  text-transform: uppercase;
  font-weight: 500;
}
#goal .goal-1 {
  font-family: "Vollkorn", serif;
  font-size: 2.4em;
  font-weight: 400;
}
#goal .goal-2 {
  font-family: "Vollkorn", serif;
  font-size: 2.4em;
  font-weight: 400;
}
#charts {
  @media (min-width: 960px) {
    margin-bottom: 40px;
  }
}
.flag-icon {
  height: 13px;
  margin-left: 5px;
}

.bounce {
  animation-name: bounce;
  animation-timing-function: ease;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
#main-table {
  width: 100%;
  margin: 0 auto;
  @media (min-width: 960px) {
    width: 65%;
  }
  padding-bottom: 80px;
}
#stat-bar {
  margin-bottom: 100px;
}
.sub-header {
  font-weight: 400;
  font-size: 1.65em;
  display: block;
  margin-bottom: 30px;
  font-family: "Vollkorn", serif;
}
.table-icon {
  margin-right: 10px;
}
.table-icon-up {
  font-size: 1.9em !important;
  color: #27ddbb !important;
}
.table-icon-down {
  font-size: 1.9em !important;
  color: #ff6c62 !important;
}
.table-icon-no-change {
  color: #f9a035 !important;
  font-size: 1.2em !important;
}
.v-application .v-data-table {
  border: 0;
  td {
    border-bottom: 0 !important;
  }
  thead th {
    border-bottom: 0 !important;
    background-color: white;
  }
}
.counter-inner-container-small {
  width: 85%;
  position: relative;
}
.counter-inner-container-big {
  width: 100%;
  position: relative;
}
#c1 .circle-content {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 50%;
  z-index: 10;
  width: 100%;
}
#c2 .circle-content {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    top: 50%;
  }
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 45%;
  z-index: 10;
  width: 100%;
}
#c3 .circle-content {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 50%;
  z-index: 10;
  width: 100%;
}
.circle-content .counter {
  height: 55px;
}
.calendar-icon {
  background-color: #00211b;
  width: 25px;
  height: 25px;
  border-radius: 15px;
}
.marked-rank {
  background: #f9a035;
}
</style>
